<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    height="height"
    viewBox="0 0 13.867 10.568"
  >
    <path
      :style="iconStyle"
      d="M13.675,8.25,9.052,12.873a.661.661,0,0,1-1.128-.468V9.764H4.182a.659.659,0,0,1-.66-.66V6.462a.659.659,0,0,1,.66-.66H7.924V3.16a.662.662,0,0,1,1.128-.468l4.623,4.623A.666.666,0,0,1,13.675,8.25ZM5.283,12.735v-1.1a.331.331,0,0,0-.33-.33H2.641a.88.88,0,0,1-.88-.88V5.141a.88.88,0,0,1,.88-.88H4.953a.331.331,0,0,0,.33-.33V2.83a.331.331,0,0,0-.33-.33H2.641A2.642,2.642,0,0,0,0,5.141v5.283a2.642,2.642,0,0,0,2.641,2.641H4.953A.331.331,0,0,0,5.283,12.735Z"
      transform="translate(0 -2.499)"
    />
  </svg>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '15.801',
    },
    width: {
      type: String,
      default: '30.146',
    },
    color: {
      type: String,
      default: 'primary-green',
    },
  },
  computed: {
    /**
     * Icon Style
     * @param {void}
     * @returns {string} - Color
     * @description Assigns the specific color to the icon
     */
    iconStyle: function () {
      return {
        fill: ['$' + this.color, `var(--${this.color})`],
      }
    },
  },
}
</script>
